import React, { useState, useEffect } from 'react';
import { getRandomQuote } from '../utils/getRandomQuote'; // Adjust the path as needed

function RandomQuote() {
    const [quote, setQuote] = useState("");

    useEffect(() => {
        // Function to get a random quote and update state
        const updateQuote = () => {
            setQuote(getRandomQuote());
        };

        // Get initial quote
        updateQuote();

        // Set interval to update quote every 30 seconds
        const intervalId = setInterval(updateQuote, 30000);

        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    return (
        <div>
            <p>{quote}</p>
        </div>
    );
}

export default RandomQuote;
