import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import RandomQuote from "../../../components/RandomQuote";

function LoginIndex() {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      const response = await fetch("http://localhost:5000/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        console.log("Login successful", data);
        navigate("/");
      } else {
        setError(data);
        setLoading(false);
      }
    } catch (err) {
      console.error("Error during login", err);
      setError({ message: "An unexpected error occurred" });
      setLoading(false);
    }
  };

    const { email: emailError, password: passwordError } = error || {};

  return (
    <div className="flex min-h-screen">
        <div className="flex w-full flex-col md:flex-row">

        {/* Image Section with Green Background */}
        <div className="md:bg-emerald-500 md:min-h-screen flex flex-wrap md:w-1/2">
            <div className="items-center text-center flex flex-col relative justify-center mx-auto">
             <div className="md:block hidden text-slate-100">
                <h1 className="font-semibold text-2xl pb-2">
                 <RandomQuote/>
                </h1>
               </div>
            </div>
        </div>

        {/* Login Section */}
        <div className="flex flex-col md:flex-1 items-center justify-center">
          <div className="loginWrapper flex flex-col w-full lg:px-36 md:px-8 px-8 md:py-8">
            {/* Login Header Text */}
            <div className="hidden md:block font-medium self-center text-xl sm:text-3xl text-gray-800">
              Welcome Back!
            </div>

            {/* Separator */}
            <div className="hidden md:block relative mt-10 h-px bg-gray-300">
              <div className="absolute left-0 top-0 flex justify-center w-full -mt-2">
                <span className="bg-white px-4 text-xs text-gray-500 uppercase">
                  Login with email or username
                </span>
              </div>
            </div>
            <div className="md:hidden block my-4">
              <h1 className="text-2xl font-semibold">Login</h1>
            </div>

            {/* Login Form */}
            <div className="md:mt-10 mt-4">
              <form onSubmit={handleSubmit}>
                {/* Username */}
                <div className="flex flex-col mb-3">
                  <div className="relative">
                    <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </div>
                    <input
                      id="email"
                      type="text"
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                      className="text-sm placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full md:py-2 py-3 focus:outline-none focus:border-emerald-400"
                      placeholder="E-Mail Address"
                      aria-label="E-Mail Address"
                    />
                  </div>
                  {emailError && (
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                      {emailError[0]}
                    </span>
                  )}
                </div>

                {/* Password */}
                <div className="flex flex-col mb-6">
                  <div className="relative">
                    <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                      <FontAwesomeIcon icon={faLock} />
                    </div>
                    <input
                      id="password"
                      type="password"
                      name="password"
                      onChange={(e) => setPassword(e.target.value)}
                      className="text-sm placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full md:py-2 py-3 focus:outline-none focus:border-emerald-400"
                      placeholder="Password"
                      aria-label="Password"
                    />
                  </div>
                  {passwordError && (
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                      {passwordError[0]}
                    </span>
                  )}
                </div>

                {/* Forgot Password Link */}
                <div className="flex items-center mb-6 -mt-2 md:-mt-4">
                  <div className="flex ml-auto">
                    <Link
                      to="/forgot-password"
                      className="inline-flex font-semibold text-xs sm:text-sm text-emerald-500 hover:text-emerald-700"
                    >
                      Forgot password?
                    </Link>
                  </div>
                </div>

                {/* Button Login */}
                <div className="flex w-full">
                  <button
                    disabled={loading}
                    type="submit"
                    className="flex items-center justify-center focus:outline-none text-white text-sm bg-emerald-500 hover:bg-emerald-700 rounded-lg md:rounded md:py-2 py-3 w-full transition duration-150 ease-in"
                  >
                    <span className="mr-2 md:uppercase">
                      {loading ? "Processing..." : "Login"}
                    </span>
                  </button>
                </div>
              </form>
            </div>

            {/* Separator */}
            <div className="relative mt-6 h-px bg-gray-300">
              <div className="absolute left-0 top-0 flex justify-center w-full -mt-2">
                <span className="bg-white px-4 text-xs text-gray-500 uppercase">
                  OR
                </span>
              </div>
            </div>

            {/* Social Button */}
            <div className="flex justify-between w-full mt-6">
              <button
                disabled={loading}
                className="flex items-center justify-center focus:outline-none text-slate-500 text-sm bg-slate-200 rounded-lg md:rounded md:py-2 px-3 py-3 w-full transition duration-150 ease-in"
              >
                <FontAwesomeIcon icon={faGoogle} />
                <span className="mr-2 flex-1">Login with Google</span>
              </button>
            </div>
            {/* End Social Button */}

            {/* Register Link */}
            <div className="flex justify-center items-center my-6 md:mb-0">
              <Link
                to="/auth/register"
                className="inline-flex items-center font-bold text-emerald-500 hover:text-emerald-700 text-xs text-center"
              >
                <span className="ml-2">You don’t have an account?</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginIndex;

